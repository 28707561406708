import { AuthAxios } from "../../global-config/HttpConfig";
import { ApiURIs } from "../../global-config/ApiList";
import { IDoctorExprience } from "../models/DoctorExprience";

export default {
  GetInsert(drEx: IDoctorExprience) {
    return AuthAxios.post(ApiURIs.DrExprienceInsert, JSON.stringify(drEx));
  },
  GetUpdate(drEx: IDoctorExprience) {
    return AuthAxios.put(ApiURIs.DrExprienceUpdate, JSON.stringify(drEx));
  },
  getDelete(id: number) {
    return AuthAxios.delete(`${ApiURIs.DrExprienceDelete}/${id}`);
  }
};
