









































































































































































































































import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { IDoctorExprience } from "../../models/DoctorExprience";
import DoctorExprienceService from "../../service/DoctorExprienceService";
import DoctorEducationService from "../../service/DoctorEducationService";
import AlertService from "../../../common-app/service/AlertService";
import DoctorService from "../../service/DoctorService";

@Component
export default class Experience extends Vue {
  @Prop({ type: Array, default: () => [] })
  drExData: IDoctorExprience[];
  public drExprience: IDoctorExprience = {} as IDoctorExprience;
  public drEx: IDoctorExprience = {} as IDoctorExprience;
  public submited: boolean = false;
  public currentIndex: number = 0;
  public isCurrentEmployee: boolean = false;
  public isCurrentEmployeeUp: boolean = false;

  public get exprienceList(): IDoctorExprience[] {
    return this.drExData;
  }

  @Emit("profileCompleteEvent")
  emitData(id: number) {
    return id;
  }

  created() {
    const dict = {
      custom: {
        Designation: {
          required: "Designation is required.",
        },
        HospitalName: {
          required: "Hospital Name is required.",
        },
        StartDate: {
          required: "Start date is required.",
          beforeCurrentDate: "Start date must be before current date.",
        },
        EndDate: {
          required: "End date is required.",
          beforeCurrentDate: "End date must be before current date.",
          isAfter: "End date must be after start date.",
        },
      },
    };
    this.$validator.localize("en", dict);
  }

  submitFrom() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.submited = true;
        DoctorExprienceService.GetInsert(this.drExprience)
          .then((res) => {
            if (res.data.status) {
              this.exprienceList.push(res.data.result);
              this.submited = false;
              this.drExprience = {} as IDoctorExprience;
              this.emitData(this.exprienceList.length);
              this.$validator.reset();
              // alert("success");
              DoctorService.refreshProfileCompleteStstus(true);
              AlertService.sendMessage({ status: true, message: "success" });

            } else {
              this.submited = false;
              //alert("success");
              AlertService.sendMessage({ status: true, message: "success" });
            }
          })
          .catch((err) => {
            // console.log(err);
            this.submited = true;
          });
      }
    });
  }

  remove(id: number, index: number) {
    this.$bvModal
      .msgBoxConfirm("Are you sure you want to delete?", {
        title: "Please Confirm",
        okVariant: "success",
        okTitle: "NO",
        cancelVariant: "danger",
        cancelTitle: "YES",
        hideHeaderClose: true,
        headerClass: "justify-content-center",
        bodyClass: "text-center",
        centered: true,
        footerClass: "justify-content-center border-top-0 flex-wrap-reverse",
      })
      .then((value) => {
        if (value == false) {
          DoctorExprienceService.getDelete(id).then((res) => {
            this.exprienceList.splice(index, 1);
            this.emitData(this.exprienceList.length);
            //alert("success deleted");
            AlertService.sendMessage({ status: false, message: "Record has been deleted" });
            DoctorService.refreshProfileCompleteStstus(true);
          });
        }
      })
      .catch((err) => {
        // An error occurred
      });
  }
  openModal(drEx: IDoctorExprience, index: number) {
    if (drEx.endDate === null) {
      this.isCurrentEmployeeUp = true;
    } else {
      this.isCurrentEmployeeUp = false;
    }
    this.drEx = Object.assign({}, drEx);
    this.currentIndex = index;
    this.$bvModal.show("edit-experience");
  }

  updateFrom(scope: any) {
    if (this.isCurrentEmployeeUp) {
      // AlertService.sendMessage({ status: true, message:"Experience information Updated"});
      this.drEx.endDate = null;
    }
    this.$validator.validateAll(scope).then((valid) => {
      if (valid) {
        DoctorExprienceService.GetUpdate(this.drEx).then((res) => {
          this.exprienceList[this.currentIndex] = res.data.result;
          this.$bvModal.hide("edit-experience");
          this.$validator.reset();
          //alert("success update");
          AlertService.sendMessage({ status: true, message: "Experience information Updated" });
        });
      }
    });
  }

  dayChengeAdd(date: string) {
    if (Date.parse(this.drExprience.startDate!.toString()) >= Date.parse(date)) {
      alert("End date should be greater than Start date");
      this.$nextTick(() => {
        this.drExprience.startDate = null;
        this.drExprience.endDate = null;
      });
    }
  }
}
