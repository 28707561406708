import { AuthAxios } from "../../global-config/HttpConfig";
import { ApiURIs } from "../../global-config/ApiList";
import { IDoctorEducation } from "../models/DoctorEducation";

export default {
  GetInsert(drEdu: IDoctorEducation) {
    return AuthAxios.post(ApiURIs.DrEduInsert, JSON.stringify(drEdu));
  },
  GetUpdate(drEdu: IDoctorEducation) {
    return AuthAxios.put(ApiURIs.DrEduUpdate, JSON.stringify(drEdu));
  },
  getDelete(id: number) {
    return AuthAxios.delete(`${ApiURIs.DrEduDelete}/${id}`);
  }
};
