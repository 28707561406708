


























import { Vue, Component } from "vue-property-decorator";
import ProfileWidget from "@/common-app/components/ProfileWidget.vue";
import GeneralInfo from "./GeneralInfo.vue";
import Specializations from "./Specializations.vue";
import Experience from "./Experience.vue";
import Education from "./Education.vue";
import Award from "./Award.vue";
import DoctorDetailService from "../../service/DoctorDetailService";
import { IDoctorDetail } from "../../models/DoctorDetail";
import { ICountry } from "../../../common-app/models/Country";
import { ISpecialization } from "../../../common-app/models/Specialization";
import { IDoctorSpecialization } from "../../models/DoctorSpecialization";
import { IDoctorExprience } from "../../models/DoctorExprience";
import { IDoctorEducation } from "../../models/DoctorEducation";
import { IDoctorAward } from "../../models/DoctorAward";
import AlertService from "../../../common-app/service/AlertService";

@Component({
  components: {
    ProfileWidget,
    GeneralInfo,
    Specializations,
    Experience,
    Education,
    Award,
  },
})
export default class MyProfile extends Vue {
  public detail: IDoctorDetail = {} as IDoctorDetail;
  public countryList: ICountry[] = [];
  public specializationList: ISpecialization[] = [];
  public doctorSpecializationList: IDoctorSpecialization[] = [];
  public doctorExperienceList: IDoctorExprience[] = [];
  public doctorEducationList: IDoctorEducation[] = [];
  public doctorAwardList: IDoctorAward[] = [];
  public totalComplete: number = 0;
  public title: string = "";
  public description: string = "";

  public showStatus: boolean = false;

  created() {
    this.totalComplete = 0;
    DoctorDetailService.getProfileList().then((s) => {
      // console.log(s);
      this.countryList = s.data.countries || [];
      this.specializationList = s.data.specializations || [];
      this.doctorSpecializationList = s.data.doctorSpecializations || [];
      this.doctorExperienceList = s.data.doctorExperiences || [];
      this.doctorEducationList = s.data.doctorEducations || [];
      this.doctorAwardList = s.data.doctorAwards || [];
      this.detail = s.data.doctorDetails || ({} as IDoctorDetail);

      this.totalComplete += Object.keys(this.detail).length === 0 ? 0 : 20;
      this.totalComplete += this.doctorSpecializationList.length === 0 ? 0 : 20;
      this.totalComplete += this.doctorExperienceList.length === 0 ? 0 : 20;
      this.totalComplete += this.doctorEducationList.length === 0 ? 0 : 20;
      this.totalComplete += this.doctorAwardList.length === 0 ? 0 : 20;
    });
  }

  profileCompleteEvent(value: number) {
    if (value == 1) {
      this.totalComplete += 5;
    }

    if (value == 0) {
      this.totalComplete -= 5;
    }
  }
  triggerAlert() {
    DoctorDetailService.submitProfile().then((s) => {});

    AlertService.sendMessage({ status: true, message: "Submit your profile to accept online and virtual appointment." });

    DoctorDetailService.getProfileList().then((s) => {
      this.specializationList = s.data.specializations || [];
      this.doctorSpecializationList = s.data.doctorSpecializations || [];
      this.doctorExperienceList = s.data.doctorExperiences || [];
      this.doctorEducationList = s.data.doctorEducations || [];

      this.totalComplete += Object.keys(this.detail).length === 0 ? 0 : 20;
      this.totalComplete += this.doctorSpecializationList.length === 0 ? 0 : 20;
      this.totalComplete += this.doctorExperienceList.length === 0 ? 0 : 20;
      this.totalComplete += this.doctorEducationList.length === 0 ? 0 : 20;
      this.getProfileStatusMessage(this.detail.isProfileCompleted, this.detail.isCompletRequest);
    });
    window.location.reload();
  }
  getProfileStatusMessage(isProfileCompleted: number, isCompletRequest: boolean) {
    //debugger;
    this.showStatus = true;
    if (isCompletRequest && isProfileCompleted == 0) {
      this.title = "Pending Profile";
      this.description =
        "Your profile is under review by the BDDOT team, we will get back within 24 Hours. Thank you for your patience ";
    } else if (isProfileCompleted == 2) {
      this.title = "Deny Profile";
      this.description = "Your profile is not approved ,Please review your profile and resubmit. \n\n Reason: " + this.detail.comments;
    } else if (!isCompletRequest && isProfileCompleted == 0) {
      this.title = "Incomplete Profile";
      this.description = "please complete your profile";
    } else if (isProfileCompleted == 3) {
      this.title = "Revoked Profile";
      this.description =
        "Your profile is revoked please contact  bddotdot customer service for additional information.\n\n Reason: " +
        this.detail.comments;
    } else if (typeof isProfileCompleted === "undefined") {
      this.description = "Your profile is Incomplete.";
    } else {
      this.description = "Your profile is approved ";
    }
    // alert(this.title);
  }
}
