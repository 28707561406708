


























































































































































import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import AlertService from "../../../common-app/service/AlertService";
import { IDoctorAward } from "../../models/DoctorAward";
import DoctorAwardService from "../../service/DoctorAwardService";

@Component
export default class Award extends Vue {
  @Prop({ type: Array, default: () => [] })
  awardProp: IDoctorAward[];
  public awardObj: IDoctorAward = {} as IDoctorAward;
  public award: IDoctorAward = {} as IDoctorAward;
  public submited: boolean = false;
  public currentIndex: number = 0;
  public currentDate = new Date();

  get awardList() {
    return this.awardProp;
  }

  @Emit("profileCompleteEvent")
  emitData(id: number) {
    return id;
  }
  created() {
    this.$validator.extend("earlier", {
      getMessage(field, val) {
        return `${field} Must be less than Current Date`;
      },
      validate(value, field) {
        let start = new Date(value);
        let end = new Date();
        return end > start;
      },
    });

    const dict = {
      custom: {
        title: {
          required: "Titile is required.",
        },
        awardDate: {
          required: "End date is required.",
          beforeCurrentDate: "End date must be before current date.",
        },
      },
    };
    this.$validator.localize("en", dict);
  }

  submitFrom() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.submited = true;
        DoctorAwardService.insert(this.award)
          .then((res) => {
            if (res.data.status) {
              this.award = Object.assign({});
              this.awardList.push(res.data.result);
              this.emitData(this.awardList.length);
              this.$validator.reset();
              this.submited = false;
              AlertService.sendMessage({ status: true, message: "Award has been saved successfully" });
            } else {
              this.submited = false;
            }
          })
          .catch((ee) => {
            this.submited = false;
          });
      } else {
        this.submited = false;
      }
    });
  }

  remove(id: number, index: number) {
    this.$bvModal
      .msgBoxConfirm("Are you sure you want to delete?", {
        title: "Please Confirm",
        okVariant: "success",
        okTitle: "NO",
        cancelVariant: "danger",
        cancelTitle: "YES",
        hideHeaderClose: true,
        headerClass: "justify-content-center",
        bodyClass: "text-center",
        centered: true,
        footerClass: "justify-content-center border-top-0 flex-wrap-reverse",
      })
      .then((value) => {
        if (value == false) {
          DoctorAwardService.delete(id).then((res) => {
            this.awardList.splice(index, 1);
            this.emitData(this.awardList.length);
            AlertService.sendMessage({ status: false, message: "Award has been deleted" });
          });
        }
      })
      .catch((err) => {
        // An error occurred
      });
  }
  openModal(award: IDoctorAward, index: number) {
    this.awardObj = Object.assign({}, award);
    this.currentIndex = index;
    this.$bvModal.show("edit-award");
  }
  updateFrom(scope: any) {
    this.$validator.validateAll(scope).then((valid) => {
      if (valid) {
        DoctorAwardService.update(this.awardObj).then((res) => {
          this.awardProp[this.currentIndex] = res.data.result;
          this.$bvModal.hide("edit-award");
          this.$validator.reset();
          AlertService.sendMessage({ status: true, message: "Award has been update successfully" });
        });
      }
    });
  }

  // dayChengeUpdate(date: string) {
  //   if (Date.parse(new Date().toString()) >= Date.parse(date)) {
  //     alert("Award date should not greater than Current date");
  //     this.$nextTick(() => {
  //       this.awardObj.awardDate = null;
  //     });
  //   }
  // }
}
