








































































































































































































import { Vue, Component, Prop, Inject, Emit } from "vue-property-decorator";
import { IDoctorEducation } from "../../models/DoctorEducation";
import DoctorEducationService from "../../service/DoctorEducationService";
import AlertService from "../../../common-app/service/AlertService";
import DoctorService from "../../service/DoctorService";

@Component
export default class Education extends Vue {
  @Prop({ type: Array, default: () => [] })
  drEduData: IDoctorEducation[];
  public drEducation: IDoctorEducation = {} as IDoctorEducation;
  public drEdu: IDoctorEducation = {} as IDoctorEducation;
  public submited: boolean = false;
  public currentIndex: number = 0;

  get educationList(): IDoctorEducation[] {
    return this.drEduData;
  }

  public get value(): Date {
    return new Date();
  }

  @Emit("profileCompleteEvent")
  emitData(id: number) {
    return id;
  }

  created() {
    const dict = {
      custom: {
        title: {
          required: "Titile is required.",
        },
        InstitutionName: {
          required: "Institute name is required.",
        },
        StartDate: {
          required: "Start date is required.",
          beforeCurrentDate: "Start date must be before current date.",
        },
        EndDate: {
          required: "End date is required.",
          beforeCurrentDate: "End date must be before current date.",
          isAfter: "End date must be after start date.",
        },
      },
    };
    this.$validator.localize("en", dict);
  }

  mounted() {
    // this.$validator.validate().then(valid => {
    //   console.log(valid);
    // });
    //console.log(this.$validator.validateAll());
  }

  submitFrom() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.submited = true;
        DoctorEducationService.GetInsert(this.drEducation)
          .then((res) => {
            if (res.data.status) {
              this.educationList.push(res.data.result);
              this.submited = false;
              this.drEducation = {} as IDoctorEducation;
              this.emitData(this.educationList.length);
              this.$validator.reset();
              //alert("success");
              AlertService.sendMessage({ status: true, message: "success" });
              DoctorService.refreshProfileCompleteStstus(true);
            } else {
              this.submited = false;
              //alert("success");
              // AlertService.sendMessage("success");
            }
          })
          .catch((err) => {
            // console.log(err);
            this.submited = true;
          });
      } else {
        this.submited = false;
      }
    });
  }
  remove(id: number, index: number) {
    this.$bvModal
      .msgBoxConfirm("Do you really want to delete?", {
        title: "Please Confirm",
        okVariant: "success",
        okTitle: "NO",
        cancelVariant: "danger",
        cancelTitle: "YES",
        hideHeaderClose: true,
        headerClass: "justify-content-center",
        bodyClass: "text-center",
        centered: true,
        footerClass: "justify-content-center border-top-0 flex-wrap-reverse",
      })
      .then((value) => {
        if (value == false) {
          DoctorEducationService.getDelete(id).then((res) => {
            this.educationList.splice(index, 1);
            this.emitData(this.educationList.length);
            AlertService.sendMessage({ status: false, message: "An Education has been deleted." });
            DoctorService.refreshProfileCompleteStstus(true);
          });
        }
      })
      .catch((err) => {
        // An error occurred
      });
  }
  // if (confirm("Do you really want to delete?")) {
  //   DoctorEducationService.getDelete(id).then((res) => {
  //     this.educationList.splice(index, 1);
  //     this.emitData(this.educationList.length);
  //     //alert("success deleted");
  //     AlertService.sendMessage("An Education has been deleted.");
  //   });
  // }
  openModal(drEducation: IDoctorEducation, index: number) {
    this.drEdu = Object.assign({}, drEducation);
    this.currentIndex = index;
    this.$bvModal.show("edit-education");
  }

  updateFrom(scope: any) {
    this.$validator.validateAll(scope).then((valid) => {
      if (valid) {
        DoctorEducationService.GetUpdate(this.drEdu).then((res) => {
          this.educationList[this.currentIndex] = res.data.result;
          this.$bvModal.hide("edit-education");
          this.$validator.reset();
          AlertService.sendMessage({ status: true, message: "success update" });
        });
      }
    });
  }
}
