





























































































import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import { ICountry } from "../../../common-app/models/Country";
import { ISpecialization } from "../../../common-app/models/Specialization";
import { IDoctorSpecialization } from "../../models/DoctorSpecialization";
import DoctorSpecializationService from "../../service/DoctorSpecializationService";
import AlertService from "../../../common-app/service/AlertService";

import DoctorDetailService from "../../service/DoctorDetailService";
import { IDoctorDetail } from "../../models/DoctorDetail";
import { IDoctorExprience } from "../../models/DoctorExprience";
import { IDoctorEducation } from "../../models/DoctorEducation";
import { IDoctorAward } from "../../models/DoctorAward";
import DoctorService from "../../service/DoctorService";

@Component
export default class Specializations extends Vue {
  @Prop({ default: () => [] })
  countryData: ICountry[];
  @Prop({ default: () => [] })
  specializationData: ISpecialization[];
  @Prop({ default: () => [] })
  doctorSpecializationData: IDoctorSpecialization[];

    public detail: IDoctorDetail = {} as IDoctorDetail;
  public specializationList: ISpecialization[] = [];
  public doctorSpecializationList: IDoctorSpecialization[] = [];
  public doctorExperienceList: IDoctorExprience[] = [];
  public doctorEducationList: IDoctorEducation[] = [];
  public doctorAwardList: IDoctorAward[] = [];
  public totalComplete: number = 0;

  public specializationModel: any = "";
  public countryModel: any = "";
  public submitted: boolean = false;

  @Emit("profileCompleteEvent")
  emitData(id: number) {
    return id;
  }

  created() {}
  addSpecalization() {
    this.$validator.validate().then((valid) => {
      if (valid) {
        if (
          this.doctorSpecializationData.filter(
            (e) => e.specialisationName == this.specializationModel.name
          ).length > 0
        ) {
          AlertService.sendMessage({ status: false, message: "already added" });
          return;
        }

        this.submitted = true;
        let obj = {
          specialisationId: this.specializationModel.id,
          specialisationName: this.specializationModel.name,
          countryId: this.countryModel.id,
          countryName: this.countryModel.name,
        } as IDoctorSpecialization;
        DoctorSpecializationService.GetInsert(obj)
          .then((res) => {
            if (res.data.status) {
              AlertService.sendMessage({
                status: res.data.status,
                message: "Specialization added successfully",
              });
              // alert("success");
              this.doctorSpecializationData.push(res.data.result);
              this.emitData(this.doctorSpecializationData.length);
              this.submitted = false;
              this.$validator.reset();
              DoctorService.refreshProfileCompleteStstus(true);
            } else {
              // alert("faild");
              AlertService.sendMessage({
                status: false,
                message: "Failed to add Specialization",
              });
              this.submitted = true;
            }
            //this. GetData();
          })
          .catch((er) => alert(er));
      } else {
        this.submitted = true;
      }
    });
  }

  remove(id: number, index: number) {
    this.$bvModal
      .msgBoxConfirm("Are you sure you want to delete?", {
        title: "Please Confirm",
        okVariant: "success",
        okTitle: "NO",
        cancelVariant: "danger",
        cancelTitle: "YES",
        hideHeaderClose: true,
        headerClass: "justify-content-center",
        bodyClass: "text-center",
        centered: true,
        footerClass: "justify-content-center border-top-0 flex-wrap-reverse",
      })
      .then((value) => {
        if (value == false) {
          DoctorSpecializationService.getDelete(id).then((res) => {
            if (res.data.status) {
              this.doctorSpecializationData.splice(index, 1);
              this.emitData(this.doctorSpecializationData.length);
              //alert("success");
              AlertService.sendMessage({
                status: res.data.status,
                message: "Specialization Removed",
              });
              DoctorService.refreshProfileCompleteStstus(true);
            } else {
              alert("faild");
            }
           //this. GetData();
          });
        }
      })
      .catch((er) => {
        // console.log(er);
      });
  }

  GetData()
  {
    this.totalComplete=0;
    DoctorDetailService.getProfileList().then((s) => {
      // console.log(s);
      this.doctorSpecializationList = s.data.doctorSpecializations || [];
      this.doctorExperienceList = s.data.doctorExperiences || [];
      this.doctorEducationList = s.data.doctorEducations || [];
      this.doctorAwardList = s.data.doctorAwards || [];
      this.detail = s.data.doctorDetails || ({} as IDoctorDetail);

      this.totalComplete += Object.keys(this.detail).length === 0 ? 0 : 5;
      this.totalComplete += this.doctorSpecializationList.length === 0 ? 0 : 5;
      this.totalComplete += this.doctorExperienceList.length === 0 ? 0 : 5;
      this.totalComplete += this.doctorEducationList.length === 0 ? 0 : 5;
      this.totalComplete += this.doctorAwardList.length === 0 ? 0 : 5;
      //alert(this.totalComplete);
     
    if(this.totalComplete==20)
      {
      location.reload();
      }
    });
  }


  
}
