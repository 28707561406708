import { AuthAxios } from "../../global-config/HttpConfig";
import { ApiURIs } from "../../global-config/ApiList";
import { IDoctorSpecialization } from "../models/DoctorSpecialization";

export default {
  GetInsert(drSp: IDoctorSpecialization) {
    return AuthAxios.post(ApiURIs.DrSpecializationInsert, JSON.stringify(drSp));
  },
  getDelete(id: number) {
    return AuthAxios.delete(`${ApiURIs.DrSpecializationDelete}/${id}`);
  }
};
