import { AuthAxios } from "../../global-config/HttpConfig";
import { ApiURIs } from "../../global-config/ApiList";
import { IDoctorAward } from "../models/DoctorAward";

export default {
  insert(awaed: IDoctorAward) {
    return AuthAxios.post(ApiURIs.DrAwardInsert, JSON.stringify(awaed));
  },
  update(awaed: IDoctorAward) {
    return AuthAxios.put(ApiURIs.DrAwardUpdate, JSON.stringify(awaed));
  },
  delete(id: number) {
    return AuthAxios.delete(`${ApiURIs.DrAwardDelete}/${id}`);
  }
};
