




















import { Vue, Component, Emit,Prop } from "vue-property-decorator";
@Component
export default class DenyProfile extends Vue {
  // @Prop({ required: true }) public title: any;
 @Prop(String) private Title!: string;
  public isShow: boolean = true;
 public title2:any="";
  @Emit("close")
  close() {
    this.isShow = false;
    return this.isShow;
  }
}
