




















import { Vue, Component, Emit,Prop } from "vue-property-decorator";
@Component
export default class IncompleteProfile extends Vue {
  @Prop({ required: true }) Title: any;
    @Prop({ required: true }) Description: any;
  public isShow: boolean = true;

  @Emit("close")
  close() {
    this.isShow = false;
    return this.isShow;
  }
  mounted(){

  }
}
