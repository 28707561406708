















































































































































































































































































































import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import { IDoctorDetail } from "../../models/DoctorDetail";
import DoctorDetailService from "../../service/DoctorDetailService";
import AlertService from "../../../common-app/service/AlertService";
import AuthUser from "../../../global-config/AuthUser";
import UserService from "../../../common-app/service/UserService";
import { IUser } from "../../../common-app/models/User";

import { ISpecialization } from "../../../common-app/models/Specialization";
import { IDoctorSpecialization } from "../../models/DoctorSpecialization";
import { IDoctorExprience } from "../../models/DoctorExprience";
import { IDoctorEducation } from "../../models/DoctorEducation";
import IncompleteProfile from "./IncompleteProfile.vue";
import DenyProfile from "./DenyProfile.vue";
import DoctorService from "../../service/DoctorService";

@Component({
  components: {
    IncompleteProfile,
    DenyProfile,
  },
})
export default class GeneralInfo extends Vue {
  @Prop({ required: true }) detailData: IDoctorDetail;
  // @Prop({ type: Object as () => IDoctorDetail, default: () => {} })
  // detailData: IDoctorDetail;

  public editingDoctorProfile: boolean = false;
  public submited: boolean = false;
  public generalInfoButton: any = { text: "Edit Info", value: true };
  //public detail: IDoctorDetail = {} as IDoctorDetail;
  public alertState: boolean = false;
  public alertMessage: string = "";
  public detail: IDoctorDetail = { gender: 0 } as IDoctorDetail;
  public userDetail: IUser = {} as IUser;
  public user = AuthUser.getAuthUser();

  public specializationList: ISpecialization[] = [];
  public doctorSpecializationList: IDoctorSpecialization[] = [];
  public doctorExperienceList: IDoctorExprience[] = [];
  public doctorEducationList: IDoctorEducation[] = [];
  public totalComplete: number = 0;
  public showIncompleteProfile: boolean = false;
  public showDenyProfile: boolean = false;
  public title: string = "";
  public description: string = "";

  public showStatus: boolean = false;

  @Watch("detailData", { deep: true })
  dataGet(f: IDoctorDetail) {
    this.detail = f;
  }

  @Emit("profileCompleteEvent")
  emitData(id: number) {
    return id;
  }

  created() {
    DoctorService.refreshProfileComplete().subscribe((res) => {
      if (res) {
        this.getprofileCompleteValue();
      }
    });
    this.getprofileCompleteValue();
  }

  getprofileCompleteValue() {
    this.totalComplete = 0;
    DoctorDetailService.getProfileList().then((s) => {
      //console.log(s);
      this.specializationList = s.data.specializations || [];
      this.doctorSpecializationList = s.data.doctorSpecializations || [];
      this.doctorExperienceList = s.data.doctorExperiences || [];
      this.doctorEducationList = s.data.doctorEducations || [];

      this.totalComplete += Object.keys(this.detail).length === 0 ? 0 : 20;
      this.totalComplete += this.doctorSpecializationList.length === 0 ? 0 : 20;
      this.totalComplete += this.doctorExperienceList.length === 0 ? 0 : 20;
      this.totalComplete += this.doctorEducationList.length === 0 ? 0 : 20;
      this.getProfileStatusMessage(this.detail.isProfileCompleted, this.detail.isCompletRequest);
      //console.log(this.detail);
    });
  }

  getFormValues(e: any) {
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.submited = true;
        if (this.detail.id > 0) {
          DoctorDetailService.Update(this.detail).then((res) => {
            if (res.data.status) {
              //AlertService.sendMessage({ status: true, message: "Updated Successfully" });
              AlertService.sendMessage({ status: true, message: "Please sign out sign in back to see the changes" });

              this.detail = Object.assign({}, res.data.result || {});
              this.getFromRestore(res);
              this.emitData(3);
            } else {
              this.submited = true;
              AlertService.sendMessage({ status: res.data.status, message: res.data.message });
            }
          });
          this.userDetail.firstName = this.user.firstName;
          this.userDetail.email = this.user.email;
          this.userDetail.phone = this.user.phone;

          UserService.Update(this.userDetail).then((res) => {
            if (res.data.status) {
              //AlertService.sendMessage({ status: true, message: "Update successfully" });
              AlertService.sendMessage({ status: true, message: "Please sign out sign in back to see the changes" });
              this.userDetail = Object.assign({}, res.data.result || {});
              this.submited = false;
            } else {
              this.submited = true;
              AlertService.sendMessage(res.data.message);
            }
          });
        } else {
          DoctorDetailService.Insert(this.detail)
            .then((res) => {
              if (res.data.status) {
                AlertService.sendMessage({ status: true, message: "Save Successfully" });
                this.detail = Object.assign({}, res.data.result || {});
                this.getFromRestore(res);
                this.emitData(1);
              } else {
                this.submited = true;
                AlertService.sendMessage({ status: res.data.status, message: res.data.message });
              }
            })
            .catch((s) => {
              this.submited = true;
              AlertService.sendMessage("ctch");
            });
        }
      } else {
        this.submited = true;
      }
    });
  }

  generalInfoView() {
    this.editingDoctorProfile = !this.editingDoctorProfile;
    if (this.generalInfoButton.value) {
      this.submited = true;
      this.generalInfoButton = { text: "Cancel", value: false };
    } else {
      this.submited = false;
      // this.detail = {} as IDoctorDetail;
      this.generalInfoButton = { text: "Edit Info", value: true };
    }
  }
  getFromRestore(res: any) {
    //this.detail = {} as IDoctorDetail;
    //this.detail = Object.assign({}, res.data.result);
    this.generalInfoButton = { text: "Edit Info", value: true };
    this.editingDoctorProfile = !this.editingDoctorProfile;
    this.submited = false;
    this.$validator.reset();
  }
  triggerAlert() {
    DoctorDetailService.submitProfile().then((s) => {});

    AlertService.sendMessage({ status: true, message: "Submit your profile to accept online and virtual appointment." });

    DoctorDetailService.getProfileList().then((s) => {
      this.specializationList = s.data.specializations || [];
      this.doctorSpecializationList = s.data.doctorSpecializations || [];
      this.doctorExperienceList = s.data.doctorExperiences || [];
      this.doctorEducationList = s.data.doctorEducations || [];

      this.totalComplete += Object.keys(this.detail).length === 0 ? 0 : 20;
      this.totalComplete += this.doctorSpecializationList.length === 0 ? 0 : 20;
      this.totalComplete += this.doctorExperienceList.length === 0 ? 0 : 20;
      this.totalComplete += this.doctorEducationList.length === 0 ? 0 : 20;
      this.getProfileStatusMessage(this.detail.isProfileCompleted, this.detail.isCompletRequest);
    });
    window.location.reload();
  }
  openIncompleteProfile() {
    this.showIncompleteProfile = true;
  }
  IncompleteProfileClose() {
    this.showIncompleteProfile = false;
  }

  getProfileStatusMessage(isProfileCompleted: number, isCompletRequest: boolean) {
    //debugger;
    this.showStatus = true;
    if (isCompletRequest && isProfileCompleted == 0) {
      this.title = "Pending Profile";
      this.description =
        "Your profile is under review by the BDDOT team, we will get back within 24 Hours. Thank you for your patience ";
    } else if (isProfileCompleted == 2) {
      this.title = "Deny Profile";
      this.description = "Your profile is not approved ,Please review your profile and resubmit. \n\n Reason: " + this.detail.comments;
    } else if (!isCompletRequest && isProfileCompleted == 0) {
      this.title = "Incomplete Profile";
      this.description = "please complete your profile";
    } else if (isProfileCompleted == 3) {
      this.title = "Revoked Profile";
      this.description =
        "Your profile is revoked please contact  bddotdot customer service for additional information.\n\n Reason: " +
        this.detail.comments;
    } else if (typeof isProfileCompleted === "undefined") {
      this.description = "Your profile is Incomplete.";
    } else {
      this.description = "Your profile is approved ";
    }
    // alert(this.title);
  }
}
